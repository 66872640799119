import {PLAN_SUBSCRIPTION_STATUS} from "../types/customer";
import {ENV} from "../utils/constants";
import {LocaleCommonType} from "./index";

const it: LocaleCommonType = {
    foreignProvince: "Estero",
    locale: {
        en: "EN",
        it: "IT",
    },
    user: {
        profile: "Profilo utente",
        account: "Account",
        logout: "Disconnetti",
        login: "Accedi",
        support: "Supporto",
        name: "Nome",
        email: "Email",
        verified: "Email verificata",
        modify: "Modifica",
        save: "Salva modifiche",
        resetPasswordMessage: "Un'email è stata inviata al tuo indirizzo con le istruzioni per cambiare la password",
        resendEmail: "invia nuovamente",
        resendEmailMessage: "Un'email è stata inviata al tuo indirizzo con le istruzioni per la verifica dell'account. Dopo aver verificato il tuo account, ricarica questa pagina",
        billing: "Dati di fatturazione",
        billingSaved: "Dati di fatturazione salvati",
        subscriptions: "Riepilogo sottoscrizioni",
        subscriptionsEmpty: "In questa pagina potrai visualizzare il riepilogo delle tue sottoscrizioni. Al momento non è presente alcuna sottoscrizione. <br/> <br/> <br/> <a href=\"/\" class=\"link\">Torna alla pagina principale dello Store</a>",
        subsTable: {
            "id": "Id",
            "product": "Soluzione",
            "total": "Importo Netto",
            "status": "Stato",
            "created": "Data di creazione",
            "startDate": "Inizio",
            "cancelAt": "Data di scadenza",
            "canceledAt": "Fine effettivo",
            "downloadInvoice": "Scarica Fattura",
            "cancel": "Annulla",
            "access": "Accedi",
            autoRenewal: "Rinnovo automatico",
            canceledTooltip: "La sottoscrizione è stata cancellata. Dalla data specificata non verrà più erogato il servizio.",
            renewTooltip: "Data di rinnovo automatico della sottoscrizione."
        },
        status: {
            [PLAN_SUBSCRIPTION_STATUS.ACTIVE]: "Attiva",
            [PLAN_SUBSCRIPTION_STATUS.CANCELED]: "Cancellata",
            [PLAN_SUBSCRIPTION_STATUS.INCOMPLETE]: "Incompleta",
            [PLAN_SUBSCRIPTION_STATUS.UNPAID]: "Non pagata",
            [PLAN_SUBSCRIPTION_STATUS.PAST_DUE]: "Pagamento scaduto",
            [PLAN_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED]: "Incompleta scaduta",
            [PLAN_SUBSCRIPTION_STATUS.TRIALING]: "Fase di prova",
        },
        invoices: "Fatture",
        invoicesEmpty: "In questa pagina potrai visualizzare tutte le tue fatture. Al momento non è presente alcuna fattura. <br/> <br/> <br/> <a href=\"/\" class=\"link\">Torna alla pagina principale dello Store</a>",
        invoiceTable: {
            id: "Id",
            solution: "Soluzione",
            type: "Tipo",
            net: "Importo netto",
            date: "Data"
        },
        invoiceType: {
            FAT: "Fattura",
            NDC: "Nota di credito",
        },
    },
    home: {
        title: "Store",
        subtitle: "Benvenuti nello store digitale di <a href='https://www.kopjra.com' target='_blank' rel='noopener noreferrer' class='link'>Kopjra</a>",
        contactus: "Hai bisogno di un preventivo su misura?",
        request: "Richiedi un preventivo",
        contactSubject: "Soluzione su misura: [SOLUZIONE]",
        contactBody: "Salve,\nSaremmo interessati a una soluzione su misura di [SOLUZIONE],\n",
    },
    admin: {
        title: "Amministrazione",
        cardTitle: "Web Forensics",
        description: "Assegna una sottoscrizione di Web Forensics a un utente. Se l'utente è nuovo sarà creato automaticamente e una password verrà automaticamente generata e inviata via email.",
        addUpdate: "Crea/Aggiorna",
        email: "Email",
        firstName: "Nome",
        lastName: "Cognome",
        package: "Pacchetto",
        created: `<div class="text-center">
        <i class="fal fa-circle-check text-success fa-3x"></i><br/><b>Utente creato con successo</b><br/>
        Una password generata automaticamente è stata inviata all'indirizzo email specificato<br/>
        E la sottoscrizione selezionata è stata assegnata all'utente<br/>
        </div>
        `,
        updated: `<div class="text-center">
        <i class="fal fa-circle-info text-primary fa-3x"></i><br/> <b>Utente aggiornato con successo</b><br/>      
        La sottoscrizione selezionata è stata assegnata all'utente<br/>
        </div>
        `,
        error: `<div class="text-center">
        <i class="fal fa-circle-exclamation text-danger fa-3x"></i><br/> <b>Errore creando/aggiornando l'utente</b><br/>      
        %{errorMessage}<br/>
        </div>
        `,
    },
    superAdmin: {
        title: "GOD MODE",
        cardTitle: "Strumenti di amministrazione",
        exportCustomerCSV: "Esporta tutti i customer da Stripe",
        cardConsumptionTitle: "Consumi",
        email: "Email",
        partner: "Partner",
        activeSolutions: "Soluzioni attive",
        activeProducts: "Prodotti attivi",
        details: "Dettagli",
        currentConsumed: "Consumo attuale",
    },
    "web-intelligence": {
        title: "Web Intelligence",
        subtext: "per il pacchetto base",
        subtitle: "Gestione di investigazioni su Internet",
        customButtonLabel: "Richiedi preventivo",
        customCTA: "https://mo6psbxn70h.typeform.com/to/rYJ0xsbp#answers-activity=15edb365-ca5a-4752-b41f-c61e3e47c010",
        url: "https://app.webintelligence.kopjra.com",
        recurrence: "Ricorrenza",
        planLabel: "Pacchetto",
        description: "Suite di soluzioni per la gestione di approfondite investigazioni su Internet e in particolare per l’individuazione e rimozione tempestiva di violazioni della reputazione, della proprietà intellettuale e industriale su clear, deep e dark web.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Per <b>%{count}</b> crediti paga un fisso di: <b>%{fixed}/%{duration}</b> con un over-quota di: <b>%{price}/cu</b> - Validità <b>1 %{duration}</b>",
            item_1: "Per <b>%{count}</b> credito paga un fisso di: <b>%{fixed}/%{duration}</b> con un over-quota di: <b>%{price}/cu</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Per %{count} crediti / %{duration}",
                item_1: "Per %{count} credito / %{duration}",
            },
            durationDetail: "I crediti hanno validità di un <b>%{duration}</b> dall'acquisto, e possono essere usati in tutti prodotti della suite Web Intelligence.",
        },
        package: {
            item: "Pacchetto da <b>%{count}</b> crediti Web Intelligence per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "Pacchetto da <b>%{count}</b> credito Web Intelligence per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} crediti / %{duration}",
                item_1: "Pacchetto da %{count} credito / %{duration}",
            },
            durationDetail: "I crediti hanno validità di un <b>%{duration}</b> dall'acquisto, e possono essere usati in tutti prodotti della suite Web Intelligence.",
            plan1: {
                month: "Pacchetto da <b>%{count}</b> ricerche a <b>%{price}</b>",
                month_1: "Una ricerca a <b>%{price}</b>",
                year: "Pacchetto da <b>%{count}</b> ricerche a <b>%{price}</b>",
                year_1: "Una ricerca a <b>%{price}</b>",
                conditions: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. L'utente avrà accesso a detection e analisi dei risultati."
            },
            plan2: {
                month: "Pacchetto da <b>%{count}</b> ricerche, <b>3</b> pozzi di takedown e <b>1000</b> notifiche di rimozione a <b>%{price}</b>",
                month_1: "Una ricerca, <b>3</b> pozzi di takedown e <b>1000</b> notifiche di rimozione a <b>%{price}</b>",
                year: "Pacchetto da <b>%{count}</b> ricerche, <b>3</b> pozzi di takedown e <b>12000</b> notifiche di rimozione a <b>%{price}</b>",
                year_1: "Una ricerca, <b>3</b> pozzi di takedown e <b>12000</b> notifiche di rimozione at <b>%{price}</b>",
                conditions: "Le ricerche e notifiche sono rinnovate ogni <b>%{duration}</b> in modo implicito, invece i pozzi sono fissi nel tempo. L'utente avrà accesso a detection, analisi e takedown dei risultati."
            },
            freeTrial: {
                month: "Pacchetto <b>gratuito</b> da <b>%{count}</b> ricerche al <b>%{duration}</b>",
                month_1: "Una ricerca <b>gratis</b> al <b>%{duration}</b>",
                year: "Pacchetto <b>gratuito</b> da <b>%{count}</b> ricerche all'<b>anno</b>",
                year_1: "Una ricerca <b>gratis</b> all'<b>anno</b>",
                conditions: "Periodo di prova gratuito che da accesso alla detection e analisi e viene rinnovato ogni <b>%{duration}</b>."
            },
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni ricerca</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti.",
        },
    },
    "web-forensics": {
        title: "Web Forensics",
        subtitle: "Acquisizione forense di prove su Internet",
        url: "https://app.webforensics.kopjra.com",
        recurrence: "Durata",
        planLabel: "Pacchetto",
        description: "Soluzione per l'acquisizione di pagine web da produrre come prove nei giudizi, rispettando le best practice internazionali dell'informatica forense, ovvero lo standard ISO/IEC 27037:2012.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Per <b>%{count}</b> crediti paga un fisso di: <b>%{fixed}/%{duration}</b> con un over-quota di: <b>%{price}/cu</b> - Validità <b>1 %{duration}</b>",
            item_1: "Per <b>%{count}</b> credito paga un fisso di: <b>%{fixed}/%{duration}</b> con un over-quota di: <b>%{price}/cu</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Per %{count} crediti / %{duration}",
                item_1: "Per %{count} credito / %{duration}",
            },
            durationDetail: "I crediti hanno validità di un <b>%{duration}</b> dall'acquisto.<br/>Un credito rappresenta 5 minuti di navigazione forense.",
        },
        package: {
            item: "Pacchetto da <b>%{count}</b> crediti per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "Pacchetto da <b>%{count}</b> credito per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} crediti / %{duration}",
                item_1: "Pacchetto da %{count} credito / %{duration}",
            },
            durationDetail: "I crediti hanno validità di un <b>%{duration}</b> dall'acquisto.<br/>Un credito equivale a <b>5 minuti</b> di navigazione forense, a scatti anticipati.<br/>È possibile utilizzare più crediti in una singola sessione, fino a un massimo di <b>18</b>, per un’acquisizione della durata complessiva di <b>90 minuti</b>.",
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni credito</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti.",
        },
    },
    "web-trust": {
        title: "Web Trust",
        subtitle: "Firma elettronica di interazioni su Internet",
        url: "https://app.webtrust.kopjra.com",
        recurrence: "Ricorrenza",
        description: "Web Trust è una suite di soluzioni API proprietarie e brevettate per la firma elettronica di interazioni avvenute su pagine web, come ad esempio l’accettazione di informative privacy e la sottoscrizione di contratti, anche in formato PDF.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
    },
    "web-sign": {
        title: "Web Sign",
        subtitle: "Firma elettronica di documenti",
        url: "https://app.websign.kopjra.com",
        recurrence: "Ricorrenza",
        planLabel: "Quantità",
        description: "Piattaforma in cloud brevettata per la gestione di complessi flussi di firma a partire da documenti e modelli in formato PDF, prima in assoluto a seguire i principi dell’informatica forense.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova..",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Pacchetto da <b>%{count}</b> firme a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            item_1: "Pacchetto da <b>%{count}</b> firma a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Per %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito.",
        },
        package: {
            freeTrial: {
                month: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firme al <b>%{duration}</b>",
                month_1: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firma al <b>%{duration}</b>",
                conditions: "Periodo di prova <b>gratuito</b> che da a un numero limitato di firme e viene rinnovato ogni <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> firme per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> firma per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} firme / %{duration}",
                item_1: "Pacchetto da %{count} firma / %{duration}",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito.",
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni firma</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti.",
        },
        addons: {
            domain: "Dominio dedicato: <b>%{price}/%{duration}</b>",
            activation: "Costo della prima attivazione: <b>%{price} una tantum</b>",
            shorts: {
                domain: "Dominio dedicato",
                activation: "Attivazione una tantum",
            }
        },
    },
    "timestamp-api": {
        title: "Timestamp API",
        subtitle: "Marca temporale",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/timestamp`,
        recurrence: "Ricorrenza",
        planLabel: "Quantità",
        description: "Soluzione API per la marcatura temporale di documenti, qualificata e trustless, ovvero tramite blockchain Bitcoin.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Pacchetto da <b>%{count}</b> marche a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/marca</b>",
            item_1: "Pacchetto da <b>%{count}</b> marca a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/marca</b>",
            shorts: {
                item: "Pacchetto da %{count} marche temporali",
                item_1: "Pacchetto da %{count} marca temporale",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        package: {
            freeTrial: {
                year: "Pacchetto <b>gratuito</b> da <b>%{count}</b> marche temporali al <b>%{duration}</b>",
                year_1: "Pacchetto <b>gratuito</b> da <b>%{count}</b> marca temporale al <b>%{duration}</b>",
                conditions: "Periodo di prova <b>gratuito</b> che da un numero limitato di marche temporali e viene rinnovato ogni <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> mache temporali per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> marca temporale per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} marche temporali",
                item_1: "Pacchetto da %{count} marca temporale",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito.",
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni marcatura</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti.",
        },
        addons: {
            domain: `Personalizzazione del dominio dell'ambiente di firma a <b>%{price}</b> al <b>%{duration}</b>`,
            "setup-fee": `Attivazione e supporto all’integrazione <b>%{price}</b>`,
            shorts: {
                domain: `Personalizzazione del dominio dell'ambiente di firma`,
                "setup-fee": `Attivazione e supporto all’integrazione`,
            }
        }
    },
    "document-api-simple": {
        title: "Document Simple API",
        subtitle: "Firma elettronica semplice di documenti",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/pdf`,
        recurrence: "Ricorrenza",
        planLabel: "Quantità",
        description: "Soluzione API per la compilazione e firma elettronica di informative e contratti in formato PDF.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Pacchetto da <b>%{count}</b> firme a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            item_1: "Pacchetto da <b>%{count}</b> firma a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        package: {
            freeTrial: {
                year: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firme al <b>%{duration}</b>",
                year_1: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firma al <b>%{duration}</b>",
                conditions: "Periodo di prova <b>gratuito</b> che da un numero limitato di firme e viene rinnovato ogni <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> firme per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> firma per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni firma</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti.",
        },
        addons: {
            domain: `Personalizzazione del dominio dell'ambiente di firma a <b>%{price}</b> al <b>%{duration}</b>f`,
            personalization: `Personalizzazione dell'ambiente di firma a <b>%{price}</b> al <b>%{duration}</b>`,
            "setup-fee": `Attivazione e supporto all’integrazione <b>%{price}</b>`,
            shorts: {
                domain: `Personalizzazione del dominio dell'ambiente di firma`,
                personalization: `Personalizzazione dell'ambiente di firma`,
                "setup-fee": `Attivazione e supporto all’integrazione`,
            }
        }
    },
    "document-api-simple-otp": {
        title: "Document Simple OTP API",
        subtitle: "Firma elettronica semplice di documenti con otp",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/pdf`,
        recurrence: "Ricorrenza",
        planLabel: "Quantità",
        description: "Soluzione API per la compilazione e firma elettronica di informative e contratti in formato PDF, con OTP via SMS.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Pacchetto da <b>%{count}</b> firme a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            item_1: "Pacchetto da <b>%{count}</b> firma a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        package: {
            freeTrial: {
                year: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firme al <b>%{duration}</b>",
                year_1: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firma al <b>%{duration}</b>",
                conditions: "Periodo di prova <b>gratuito</b> che da un numero limitato di firme e viene rinnovato ogni <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> firme per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> firma per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni firma</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti.",
        },
        addons: {
            domain: `Personalizzazione del dominio dell'ambiente di firma a <b>%{price}</b> al <b>%{duration}</b>`,
            personalization: `Personalizzazione dell'ambiente di firma a <b>%{price}</b> al <b>%{duration}</b>`,
            "setup-fee": `Attivazione e supporto all’integrazione <b>%{price}</b>`,
            shorts: {
                domain: `Personalizzazione del dominio dell'ambiente di firma`,
                personalization: `Personalizzazione dell'ambiente di firma`,
                "setup-fee": `Attivazione e supporto all’integrazione`,
            }
        }
    },
    "document-api": {
        title: "Document Simple Forensic API",
        subtitle: "Firma elettronica semplice di documenti con layer forense",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/pdf`,
        recurrence: "Ricorrenza",
        planLabel: "Quantità",
        description: "Soluzione API per la compilazione e firma elettronica di informative e contratti in formato PDF, con OTP via SMS, in un ambiente forense dedicato.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Pacchetto da <b>%{count}</b> firme a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            item_1: "Pacchetto da <b>%{count}</b> firma a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        package: {
            freeTrial: {
                year: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firme al <b>%{duration}</b>",
                year_1: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firma al <b>%{duration}</b>",
                conditions: "Periodo di prova <b>gratuito</b> che da un numero limitato di firme e viene rinnovato ogni <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> firme per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> firma per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni firma</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti.",
        },
        addons: {
            domain: `Personalizzazione del dominio dell'ambiente di firma a <b>%{price}</b> al <b>%{duration}</b>`,
            personalization: `Personalizzazione dell'ambiente di firma a <b>%{price}</b> al <b>%{duration}</b>`,
            "setup-fee": `Attivazione e supporto all’integrazione <b>%{price}</b>`,
            shorts: {
                domain: `Personalizzazione del dominio dell'ambiente di firma`,
                personalization: `Personalizzazione dell'ambiente di firma`,
                "setup-fee": `Attivazione e supporto all’integrazione`,
            }
        }
    },
    "document-api-advanced": {
        title: "Document Advanced API",
        subtitle: "Firma elettronica avanzata di documenti",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/aes`,
        recurrence: "Ricorrenza",
        planLabel: "Quantità",
        description: "Soluzione API per la compilazione e firma elettronica avanzata di informative e contratti in formato PDF, comprensiva di identificazione del firmatario tramite SPID (Sistema Pubblico di Identità Digitale).",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Pacchetto da <b>%{count}</b> firme a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            item_1: "Pacchetto da <b>%{count}</b> firma a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            shorts: {
                item: "Per %{count} firme",
                item_1: "Per %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un  <b>%{duration}</b> dall'acquisto. I dati verranno conservati per 10 anni a un prezzo extra di <b>%{linked_price}</b> per ogni documento firmato a partire dal secondo per ogni firma.",
        },
        package: {
            freeTrial: {
                year: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firme al <b>%{duration}</b>",
                year_1: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firma al <b>%{duration}</b>",
                conditions: "Periodo di prova <b>gratuito</b> che da un numero limitato di firme e viene rinnovato ogni <b>%{duration}</b>. Le firme potranno contenere al massimo <b>1</b> documento."
            },
            item: "<b>%{count}</b> firme per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> firma per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto",
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni firma</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti. I dati verranno conservati per 10 anni a un prezzo extra di <b>%{linked_price}</b> per ogni documento firmato a partire dal secondo per ogni firma.",
        },
        addons: {
            domain: `Personalizzazione nome di dominio <b>%{price} / %{duration}</b>`,
            personalization: `Personalizzazione dell'ambiente di firma <b>%{price} / %{duration}</b>`,
            "setup-fee": `Attivazione e supporto all’integrazione <b>%{price}</b>`,
            shorts: {
                domain: `Dominio`,
                personalization: `Personalizzazioni`,
                "setup-fee": `Attivazione e supporto all’integrazione`,
            }
        }
    },
    "data-api": {
        title: "Data Forensic API",
        subtitle: "Firma elettronica di flussi di dati",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/data`,
        recurrence: "Ricorrenza",
        planLabel: "Quantità",
        description: "Soluzione API per la firma elettronica di flussi di dati provenienti da applicazioni e dispositivi IoT.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Pacchetto da <b>%{count}</b> firme a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            item_1: "Pacchetto da <b>%{count}</b> firma a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        package: {
            freeTrial: {
                year: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firme al <b>%{duration}</b>",
                year_1: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firma al <b>%{duration}</b>",
                conditions: "Periodo di prova <b>gratuito</b> che da un numero limitato di firme e viene rinnovato ogni <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> firme per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> firma per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni firma</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti.",
        },
        addons: {
            "setup-fee": `Attivazione e supporto all’integrazione <b>%{price}</b>`,
            shorts: {
                "setup-fee": `Attivazione e supporto all’integrazione`,
            }
        }
    },
    "webpage-api": {
        title: "Webpage Forensic API",
        subtitle: "Firma elettronica di pagine web",
        url: `https://app${(ENV && ENV !== "prod") ? `-${ENV}` : ""}.webtrust.kopjra.com/html`,
        recurrence: "Ricorrenza",
        planLabel: "Quantità",
        description: "Soluzione API per la firma elettronica click-to-sign di informative e contratti pubblicati su pagine web.",
        exclusiveWarn: "Prodotto con sottoscrizione attiva. L'acquisto di un'altra configurazione provocherà l'eliminazione della sottoscrizione attiva e l'inizio di una nuova.",
        exclusiveError: "Prodotto con sottoscrizione attiva. La configurazione attiva di questo prodotto non ammette modifiche, si prega di attendere il termine della sottoscrizione in corso o contattare il supporto clienti.",
        level: {
            item: "Pacchetto da <b>%{count}</b> firme a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            item_1: "Pacchetto da <b>%{count}</b> firma a <b>%{fixed}/%{duration}</b> con un extra soglia di <b>%{price}/firma</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        package: {
            freeTrial: {
                year: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firme al <b>%{duration}</b>",
                year_1: "Pacchetto <b>gratuito</b> da <b>%{count}</b> firma al <b>%{duration}</b>",
                conditions: "Periodo di prova <b>gratuito</b> che da un numero limitato di firme e viene rinnovato ogni <b>%{duration}</b>."
            },
            item: "<b>%{count}</b> firme per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            item_1: "<b>%{count}</b> firma per un totale di: <b>%{price}</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "Pacchetto da %{count} firme",
                item_1: "Pacchetto da %{count} firma",
            },
            durationDetail: "La sottoscrizione ha validità di un <b>%{duration}</b> dall'acquisto, con rinnovo implicito. <br/>L'archiviazione standard inclusa ha durata di un <b>%{duration}</b> dall'acquisto.",
        },
        consumption: {
            item: "A consumo a un prezzo di <b>%{price}/ogni firma</b> - Validità <b>1 %{duration}</b>",
            shorts: {
                item: "A consumo a %{price}/cu (%{duration})",
            },
            durationDetail: "Una fattura sarà inviata alla fine del periodo di un <b>%{duration}</b> con i consumi del medesimo periodo, con rinnovo impliciti.",
        },
        addons: {
            domain: `Personalizzazione del dominio dell'ambiente di firma a <b>%{price}</b> al <b>%{duration}</b>`,
            "setup-fee": `Attivazione e supporto all’integrazione <b>%{price}</b>`,
            shorts: {
                domain: `Personalizzazione del dominio dell'ambiente di firma`,
                "setup-fee": `Attivazione e supporto all’integrazione`,
            }
        }
    },
    "analysis": {
        title: "Analisi",
        subtitle: "",
        description: ""
    },
    "detection": {
        title: "Detection",
        subtitle: "E vai che ti prendo",
        description: "Qui si cerca e cerca e valida e valida",
    },
    "takedown": {
        title: "Takedown",
        subtitle: "Ti ho preso!",
        description: "Qui si guarda guarda e diffida diffda",
    },
    productDetail: {
        selectPlanLabel: "Seleziona un piano",
        addon: "Addon",
        level: "Quantità",
        package: "Pacchetto",
        duration: "Condizioni",
        recurrence: "Ricorrenza",
        addToCart: "Acquista",
        updateCart: "Aggiorna l'ordine",
        total: "Totale",
        noiva: "(IVA esclusa)",
        iva: "(IVA inclusa)",
        selectPlan: "Seleziona il piano",
        selectPlanError: "Devi selezionare almeno un piano",
    },
    cart: {
        title: "Riepilogo ordine",
        subtotal: "Importo netto",
        iva: "IVA (%{count}%)",
        buy: "Continua a comprare",
        proceed: "Procedi all'ordine",
        edit: "Modifica",
        delete: "Elimina",
        deleteConfirm: "Sicuro di voler eliminare questo elemento dall'ordine?",
        couponTitle: "Applica coupon sconto",
        couponPlaceholder: "Coupon sconto",
        couponButton: "Applica",
        couponError: "Il coupon non può essere applicato",
        couponPercent: "Sconto di %{count}% applicato",
        couponAmount: "Sconto di %{count} € applicato",
        tos: "Accetto i <a href='https://www.iubenda.com/termini-e-condizioni/18692996' target='_blank' rel='noopener noreferrer'>termini di servizio</a> e ho letto la <a href='https://www.iubenda.com/privacy-policy/18692996' target='_blank' rel='noreferrer noopener'>privacy policy</a> di Kopjra.",
        secure: "Pagamenti sicuri e garantiti",
        financed: "PROGETTO COFINANZIATO DAL POR FESR EMILIA-ROMAGNA 2014-2020",
    },
    payment: {
        access: "Accesso",
        billing: "Fatturazione",
        payment: "Pagamento",
        outcome: "Conferma",
        summary: "Riassunto del carello",
        registerLoginTitle: "Per proccedere con l'aquisto registrati o accedi",
        registerLoginButton: "Registrati / Accedi",
        cardData: "Dati della carta",
        newCard: "Nuova carta di credito",
        paymentMethod: "Metodi di pagamento",
        deleteConfirmation: "Sicuro di voler eliminare il metodo di pagamento selezionato?",
        setDefault: "Imposta come predefinito",
        delete: "Elimina",
        deleteError: "Errore nell'eliminazione del metodo di pagamento, forse ne hai uno solo. Se è così, non può essere eliminato",
        saved: "Metodi di pagamento salvati",
        iban: "IBAN",
        sepa: "Addebito SEPA",
        expired: "Scaduta %{month}/%{year}",
        pay: "Paga",
        setError: "Errore nell'impostazione del metodo di pagamente, per favore riprovare",
        stripeError: "La piattaforma di pagamento non è pronta, per favore riprovare",
        cardError: "La carta specificata non è valida",
        sepaMissingDataError: "Il nome dell'intestatario e l'email devono essere forniti",
        sepaError: "Errore creando il metodo di pagamento SEPA",
        defaultError: "Il metodo di pagamento di default non è valido, per favore modificare",
        sepaName: "Nome intestatario",
        sepaEmail: "Email",
        emptyCart: "Nessun ordine disponibile",
        gotoStore: "Per cominciare gli acquisti procedere allo ",
        confirmEmail: "Per proseguire verifica il tuo indirizzo e-mail cliccando sul link presente nella mail ricevuta all’indirizzo <b>%{email}</b>, dopodiché clicca sul pulsante sottostante.",
        verified: "Indirizzo email verificato",
        resendEmail: "Non trovi la mail di verifica? Invia nuovamente",
        confirmation: {
            titleOK: "Ordine confermato",
            subtitleOK: "Grazie per aver ordinato su Kopjra Store. <br/><br/> La conferma dell’ordine verrà inviata all’indirizzo <b>%{email}</b>. <br/>Per iniziare a utilizzare i servizi acquistati segui le istruzioni all'interno della mail.",
            titlePartial: "Ordine parzialmente confermato",
            subtitlePartial: "Grazie per aver ordinato su Kopjra Store. <br/><br/> Il suo ordine è stato confermato parzialmente, vedi sotto. <br/>Per la parte confermata dell'ordine  verrà inviata all’indirizzo <b>%{email}</b>. <br/>Per iniziare a utilizzare i servizi acquistati segui le istruzioni all'interno della mail. <br/><br/>Gli elementi non confermati gli abbiamo lasciati nel <a href='/cart' class='link'>carrello</a>, per favore riprovare",
            titleNone: "L'ordine non è stato confermato",
            subtitleNone: "Il suo ordine non è stato confermato, per favore riprovare o contattare l'assistenza",
            confirmedTitle: "Elementi del carrello confermati",
            notConfirmedTitle: "Elmenti del carrello non confermati",
            confirmed: "Confermato",
            notConfirmed: "Non confermato",
            paymentError: "C'è stato un errore di pagamento. Potresti provare con un altra carta.",
        },
    },
    billingInfo: {
        name: "Ragione sociale",
        vat: "Partita IVA",
        street: "Indirizzo",
        number: "Civico",
        city: "Città",
        state: "Provincia",
        country: "Nazione",
        zip: "CAP",
        email: "Email",
        invoiceEmail: "Email per fatturazione",
        confirmEmail: "Conferma email",
        emailError: "Email valida obbligatoria e deve essere uguale al campo CONFERMA EMAIL",
        vatError: "Deve essere una partita IVA valida",
        required: "Obbligatorio",
        proceed: "Procedi",
        saveError: "C'è stato un errore nel salvataggio dell'informazione di fatturazione, riprova più tardi",
    },
    generic: {
        yes: "Sì",
        no: "No",
        from: "A partire da",
        confBuy: "Configura e Acquista",
        configure: "Configura",
        select: "Seleziona",
        remove: "Rimuovi",
        access: "Accedi a ",
        solutions: "Soluzioni",
        freePlan: "Prova gratis",
        modality: {
            year: "Anno",
            month: "Mese",
        },
        currency: {
            eur: "€"
        },
        recurrence: {
            day: "Giornaliera",
            year: "Annuale",
            month: "Mensile",
            week: "Settimanale",
        },
        duration: {
            day: "giorno",
            annual: "anno",
            year: "anno",
            month: "mese",
            quarter: "quadrimestre",
            semiannual: "semestre",
        },
        download: "Scarica",
    },
    error: {
        page: "Errore",
        forbidden: "Accesso negato",
        notFound: "Pagina non trovata",
        notAuthenticated: "Utente non autenticato",
        getProducts: "Errore durante il recupero dei prodotti. Ricarica la pagina",
        malformedCart: "La creazione del carrello non è stata possibile.",
    },
    kptable: {
        filters: "Filtri",
        apply: "Applica",
        show_columns: "Mostra colonne",
        pager: {
            first: "Prima",
            last: "Ultima",
        },
        actions: "Azioni",
        date: {
            short: "DD/MM/YYYY",
            long: "DD/MM/YYYY HH:mm",
        },
        confirm: {
            yes: "Sì",
            no: "No",
        },
        show: "Mostra",
        globalSelectionWarn: "Questa selezione si applica solo per i %{count} elementi visibili",
    },
    footer: {
        privacy: "Privacy",
        terms: "Termini",
        cookie: "Cookie",
        preferences: "Preferenze",
    },
    globalAlert: "",
    // globalAlert: "Manutenzione straordinaria: <b>giovedì 31 agosto 2023 dalle ore 21:00 alle ore 23:00 (CEST)</b> potrebbe non essere possibile effettuare acquisti e modificare il profilo",
};

export default it;
